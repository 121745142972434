import { useEffect, useRef, type FC } from "react";
import { Box } from "@chakra-ui/react";
import { type PageBase } from "~/foundation/Multisite/generated-types";
import { useSitecoreContext } from "~/foundation/Jss/useSitecoreContext";
import { Breadcrumbs } from "../../../_Base/components/breadcrumbs";
import { Headline, SubHeading } from "../../../_Base/components/ui/headline";
import { type ImageField } from "~/foundation/Jss";
import { ComponentContainer } from "../../layouts/component-container";
import { sc } from "../../Jss";
import "../../css/global.css";
import { imageSizes } from "../../theme";

export type PageHeaderProps = PageBase & {
	description?: {
		value: string,
	},
	image?: ImageField,
	headline: {
		value: string,
	}
}

const PageHeader: FC<PageHeaderProps> = () => {
	const { sitecoreContext } = useSitecoreContext();
	const fields = sitecoreContext.route?.fields as PageHeaderProps;
	const description = fields?.description?.value;
	const breadcrumbItems = sitecoreContext?.custom?.breadcrumbItems?.items || [];
	const fixed = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (fixed.current === null) {
			return;
		}

		const handleScroll = () => {
			const scrollValue = window.scrollY;

			// Adjust the value to control the intensity of the translation effect
			const translateValue = Math.min(scrollValue * 0.4, 400);

			if (fixed.current && fixed.current.style) {
				fixed.current.style.transform = `translateY(${translateValue}px)`;
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [fixed]);

	return (
		<Box
			as="section"
			bgColor={"#fff"}
			data-rendering="SectionHeader">
			{fields?.image?.value?.src ? <Box
				width={'full'}
				pos={'relative'}
				top="-279px"
				marginBottom="-279px"
				ref={fixed}
				height={{
					base: "430px",
					lg: "590px",
				}}
			>
				<sc.AdvanceImage
					fields={fields}
					itemId={sitecoreContext.route?.itemId}
					fieldName="image"
					srcSet={[imageSizes.parallax.md, imageSizes.parallax.lg, imageSizes.parallax.xl]}
					sizes={"100vw"}
					lazy={false}
					sx={{
						objectFit: "cover",
						objectPosition: "center",
						minWidth: "100%",
						minHeight: "100%",
					}}
				/>
			</Box> : null}
			<ComponentContainer divider="bottom" innerBottomPadding={".2rem"} themed={false}>
				{breadcrumbItems.length ? <Box
					mb={"1.5rem"}
				>
					{<Breadcrumbs items={breadcrumbItems} />}
				</Box> : null}
				<Box w={["80%", null, null, "70%"]}>
					{fields?.headline?.value && <Headline size="headline1" as="h1">
						{fields?.headline?.value}
					</Headline>}
					{(description && description !== "") &&
						<SubHeading
							size="subheader"
							fontWeight={600}
							mt={"2rem"}
						>
							<Box dangerouslySetInnerHTML={description ? { __html: description } : undefined} as="span" />
						</SubHeading>}
				</Box>
			</ComponentContainer>
		</Box>
		// </Box>
	);
};

export default PageHeader;

