import { A11y as CarouselA11y, Navigation as CarouselNavigation } from "swiper/modules";

import {
	type SwiperClass as CarouselClass,
	type SwiperProps as CarouselProps,
	type SwiperSlideProps as CarouselSlideProps,
	Swiper,
	SwiperSlide,
} from "swiper/react";

import { chakra } from "@chakra-ui/react";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/a11y";
import "swiper/css/navigation";

const Carousel = chakra<typeof Swiper, CarouselProps>(Swiper);
const CarouselSlide = chakra<typeof SwiperSlide, CarouselSlideProps>(SwiperSlide);

// https://github.com/nolimits4web/swiper/issues/4413
CarouselSlide.displayName = "SwiperSlide";

export { Carousel, CarouselA11y, CarouselNavigation, CarouselSlide };
export type { CarouselClass, CarouselProps, CarouselSlideProps };

