import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getGreyColorTheme = () => ({
	name: "Grey",
	primaryColor: "var(--chakra-colors-TechPrimary-grey)",
	primaryGradient: colors.TechBase.grey.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	secondaryColor: "var(--chakra-colors-TechPrimary-darkBlue)",
	linearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(63.93deg, rgba(235, 235, 235, 0) -3.15%, #EBEBEB 37.44%)",
	reversedLinearGradient: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(319.43deg, ${colors.TechBase.grey.replace('rgb', 'rgba').replace(')', ', 0)')} -68.76%, ${colors.TechBase.grey} 53.27%)`,
	...baseColorTheme
});

export type GreyColorTheme = ReturnType<typeof getGreyColorTheme>;