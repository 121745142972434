import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getBlueColorTheme = () => ({
	name: "Blue",
	primaryColor: "var(--chakra-colors-TechPrimary-blue)",
	secondaryColor: "var(--chakra-colors-TechPrimary-green)",
	primaryGradient: colors.TechPrimary.blue.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	linearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(63.93deg, rgba(0, 157, 214, 0) -3.15%, #00A3E0 37.44%)",
	reversedLinearGradient: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(319.43deg, ${colors.TechPrimary.blue.replace('rgb', 'rgba').replace(')', ', 0)')} -68.76%, ${colors.TechPrimary.blue} 53.27%)`,

	...baseColorTheme
});

export type BlueColorTheme = ReturnType<typeof getBlueColorTheme>;