import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getDarkGreenColorTheme = () => ({
	name: "Dark green",
	primaryColor: "var(--chakra-colors-TechPrimary-darkGreen)",
	primaryGradient: colors.TechPrimary.green.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	secondaryColor: "var(--chakra-colors-TechPrimary-white)",
	linearGradient: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(63.93deg, rgba(0, 132, 61, 0) -3.15%, #00843D 37.44%)",
	reversedLinearGradient: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(319.43deg, ${colors.TechPrimary.green.replace('rgb', 'rgba').replace(')', ', 0)')} -68.76%, ${colors.TechPrimary.green} 53.27%)`,
	...baseColorTheme
});

export type DarkGreenColorTheme = ReturnType<typeof getDarkGreenColorTheme>;