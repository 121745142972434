/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "../variables/colors";
import { pxToRem } from "../utils/mapPxToRem";

export const Link = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		display: "inline-flex",
	},
	sizes: {
		lg: {
			gap: 5,
		},
		sm: {
			gap: 3,
		},
		smallBodyBold: {
			fontSize: ["0.813rem", null, null, "0.875rem"], // [13px, null, null, 14px]
			fontWeight: "700",
			lineHeight: ["1.538", null, null, "1.571"], // [20/13, null, null, 22/14]
			letterSpacing: "0em",
			fontFamily: "ghawar",
		},
	},
	variants: {
		fullWidth: {
			width: "100%",
		},
		footerSocial: {
			alignItems: "center",
			justifyContent: "center",
			width: 30,
			height: 30,
			border: "1px solid black",
			transition: "all 0.2s",

			svg: {
				transition: "all 0.2s",
			},

			_hover: {
				background: "black",

				svg: {
					fill: "white!important",
				},
			},
		},
		footerLink: {
			py: pxToRem("6px"),
			borderTop: `1px solid ${colors.TechBase.greyBorder}`,
			borderBottom: `1px solid ${colors.TechBase.greyBorder}`,
			fontSize: pxToRem("14px"),
			fontWeight: 700,
			lineHeight: pxToRem("22px"),
			minW: [null, null, null, "100px"],
		},
		moduleLink: {
			fontFamily: "manifa",
			fontSize: [pxToRem("20px"), null, null, pxToRem("22px")], // [20px, null, null, 22px]
			fontWeight: "600",
			lineHeight: pxToRem("28px"),
			letterSpacing: [pxToRem("0.2px"), null, null, pxToRem("0.22px")], // [0.2px, null, null, 0.22px]
			h: "fit-content",
			w: "fit-content",
			display: "inline-flex",
			alignItems: "center",
			gap: 5,
		},
	},
});
