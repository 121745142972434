import { useSitecoreContext, type LinkField, type TextField } from "~/foundation/Jss";
import { Container } from "../layouts/container";
import { Box, Flex as ChakraFlex, Link, Text } from "@chakra-ui/react";
import { sc } from "../Jss";
import { pxToRem } from "../theme/utils/mapPxToRem";
import { Headline, SubHeading } from "./ui/headline";
import { Flex } from "../theme/flex";
import { useContainerContext } from "../layouts/component-container-context";
import { useColorTheme } from "../theme/ColorThemeContext";
import BundleIcon from "./ui/bundle-icon";
import { groupHoverArrowStyle } from "../theme/animations";

export type ModuleHeadlineProps = {
	headline?: TextField | undefined;
	link?: LinkField | undefined;
	subHeadline?: TextField | undefined;
}

const ModuleHeadline = ({
	headline,
	link,
	subHeadline
}: ModuleHeadlineProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const { themed } = useContainerContext();
	const { light, primaryColor } = useColorTheme();

	return (
		<Container>
			{(headline?.value || subHeadline?.value || link?.value?.href) ?
				<Box mb={["2rem", null, "4rem"]} className="module-headline">
					<Flex
						flexDir={["column", null, null, "row"]}
						alignItems={["flex-start", null, null, "flex-end"]}
						gap="0px"
					>
						<ChakraFlex
							flexDir="column"
							flex="0 0 auto"
							w={["80%", null, null, "50%"]}
						>
							{(headline || sitecoreContext.pageEditing) && <Headline as="h2" {...(themed ? { color: light } : {})} size="headline3">
								{headline?.value || sitecoreContext.pageEditing ? <sc.Text field={headline} /> : null}
							</Headline>}
							{(subHeadline || sitecoreContext.pageEditing) && (
								<SubHeading
									as="p"
									{...(themed ? { color: light } : {})}
									mt={[pxToRem("16px"), null, null, pxToRem("16px")]}
								>
									{subHeadline?.value ? <sc.Text field={subHeadline} /> : null}
								</SubHeading>
							)}


						</ChakraFlex>
						<ChakraFlex
							justifyContent={["flex-start", null, null, "flex-end"]}
							flex="1"
							mt={[pxToRem("22px"), null, null, "0"]}
						>
							{((link && link?.value?.href) || sitecoreContext.pageEditing) &&
								<Link
									variant="moduleLink"
									href={link?.value?.href}
									title={link?.value?.title}
									data-group
								>
									<Text
										{...(themed ? { color: light } : { color: primaryColor })}
										variant="headlineDescBtn"
										size="linkBtn"
										textTransform={"capitalize"}
									>
										{link?.value?.text}
									</Text>
									<BundleIcon
										name="button-arrow"
										fill={themed ? "white" : primaryColor}
										size={`lg`}
										isRtl={false}
										_groupHover={groupHoverArrowStyle(false)}
									/>
								</Link>
							}
						</ChakraFlex>
					</Flex>
				</Box> : null}

		</Container>
	);
}

export { ModuleHeadline };